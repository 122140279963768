<template>
  <div class="page-mechanism">
    <item-title :title="{name: '互联网保险', en_1: 'ONLINE', en_2: 'INSURANCE'}" />
    <div class="online-insurance">
      <!-- <div class="content" v-html="richText"></div> -->
      <div v-for="(item, index) in topData" :key="index" class="rich-text-container">
        <div class="title">{{item.name}}</div>
        <div class="content" v-html="item.content"></div>
      </div>
      <div v-if="thName" class="table-box">
        <div class="title">合作保险机构</div>
        <table>
          <tr class="hd">
            <th v-for="(item, index) in th_value" :key="index">{{item}}</th>
          </tr>
          <tr v-for="item in tableData" :key="item.id">
            <td style="max-width: 300px;" v-for="(val, key) in th_key" :key="key" >
              <div v-if="item[val] && item[val].length > 30" class="td-content">
                <span>{{item[val].substring(0, 30)}}…</span>
                <a @click="showMoreContent(item[val])" href="javascript:;" style="color: #0d6efd; display: inline-blcok; margin-left: 5px;">更多</a>
              </div>
              <div v-else class="td-content">{{item[val] || '--'}}</div>
            </td>
          </tr>
        </table>
        <div v-if="params.limit < total && total != 0" @click="loadMore" class="fd">展示更多内容 +</div>
        <div v-else class="fd"></div>
      </div>
      <div v-for="(item, index) in bottomData" :key="index" class="rich-text-container">
        <div class="title">{{item.name}}</div>
        <div class="content" v-html="item.content"></div>
      </div>
    </div>    
    <div @click.stop="previewUrl = ''" v-if="previewUrl" class="preview-modal">
      <img @click.stop="previewUrl = previewUrl" :src="previewUrl" alt="">
      <div class="close-modal">
        <img src="@/assets/img/common/common_add_white.png" alt="">
      </div>
    </div>
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="40%"
      center>
      <div style="font-size: .16rem;"><pre style="white-space: pre-wrap;word-wrap: break-word; margin-bottom: 0; line-height: .24rem;">{{td_content_text}}</pre></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
// import Load from '@/components/disclosure/load.vue'
import { getPartnerCompanyTypeList, getPartnerCompanyList, getCommonPageData, getWebInsureList, getNetSaleList } from '@/api/disclosure'
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      display: false,
      nav: [
        { id: 0, name: '全部', active: true }
      ],
      navId: 0,
      maxPage: 0,
      page: 1,
      list: [],
      loading: false,
      loaded: false,
      richText: '',
      previewUrl: '',
      timer: null,
      page_1: 1,
      pagesize_1: 5,
      page_2: 1,
      pagesize_2: 5,
      topData: [],
      bottomData: [],
      params: {
        page: 1,
        limit: 6
      },
      thName: null,
      tableData: [],
      total: 0,
      th_key: [],
      th_value: [],
      centerDialogVisible: false,
      td_content_text: ''
    }
  },
  created () {
    this.getWebInsureList()
    this.getNetSaleList()
  },
  mounted() {
    this.getNav();
    this.getPageData()
    let _this = this
    // this.$nextTick(() => {
      
    //   // let elements = document.querySelectorAll('.content a')
    //   // for (var i = 0; i < elements.length; i++) {
    //   //   console.log(elements[i])
    //   // }
    //   // _this.timer = setInterval (function () {
    //   //   let tables = document.querySelectorAll('table')
    //   //   if (tables.length > 0) {
    //   //     clearInterval(_this.timer)
    //   //     for (var i = 0; i < tables.length; i++) {
    //   //       let tbody = tables[i].children[0]
    //   //       tbody.setAttribute('index', i + 1)
    //   //       let trElements = tbody.children
    //   //       if (i == 0) {
    //   //         _this.tableHandles(trElements, _this.page_1, _this.pagesize_1)
    //   //       } else if (i == 1) {
    //   //         _this.tableHandles(trElements, _this.page_2, _this.pagesize_2)
    //   //       }
    //   //     }
    //   //   }
    //   // }, 200)
    //   // document.querySelector('.content').addEventListener('click', function (e) {
    //   //   var table_index = e.target.parentNode.parentNode.getAttribute('index')
    //   //   var trElements = e.target.parentNode.parentNode.children
    //   //   if (e.target.innerHTML.indexOf('展示更多内容+') != -1) {
    //   //     if (table_index == 1) {
    //   //       _this.pagesize_1++
    //   //       _this.tableHandles(trElements, _this.page_1, _this.pagesize_1)
    //   //     } else if (table_index == 2) {
    //   //        _this.pagesize_2++
    //   //       _this.tableHandles(trElements, _this.page_2, _this.pagesize_2)
    //   //     }
    //   //   }
    //   // })
    // })
    this.$nextTick(() => {
      // console.log(111, elements)
        this.timer = setInterval(function () {
          let elements = document.querySelectorAll('.content a')
          if (elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
              // console.log(elements[i])
              let href = elements[i].getAttribute('href')
              let arr = href.split('.')
              // console.log(arr[arr.length-1])
              let exeName = arr[arr.length-1]
              if (exeName == 'jpg' || exeName == 'png') {
                // console.log(exeName)
                elements[i].onclick = function (e) {
                  e.preventDefault();
                  _this.previewPic(href)
                }
              }
            }
            clearInterval(_this.timer)
          }
        }, 500)
      })
  },
  methods: {
    // 显示更多表格内容
    showMoreContent (content) {
      this.td_content_text = content
      this.centerDialogVisible = true
      // console.log(content)
      // console.log()
      // this.$alert(content)
      //  this.$alert('<div style="width: auto;"><pre style="white-space: pre-wrap;word-wrap: break-word; margin-bottom: 0;">'+content+'</pre></div>', {
      //     dangerouslyUseHTMLString: true
      //   });
    },
    loadMore () {
      this.params.limit = this.params.limit + 5
      this.getNetSaleList()
    },
    async getNetSaleList () {
      const res = await getNetSaleList(this.params)
      if (res.records.length > 0) {
        let th_data = res.records[0]
        let th_key = []
        let th_value = []
        let all_keys = Object.keys(th_data)
        all_keys.forEach(item => {
          if (th_data[item] && item != 'id') {
            th_key.push(item)
            th_value.push(th_data[item])
          }
        })
        this.th_key = th_key
        this.th_value = th_value
        this.thName = Object.keys(res.records[0]).slice(1, res.records[0].length)
      }
      this.tableData = JSON.parse(JSON.stringify(res.records)).splice(1, res.records.length)
      
      this.total = res.total
    },
    async getWebInsureList () {
      const res = await getWebInsureList({ page: 1, limit: 100 })
      let _this = this
      res.records.forEach(item => {
        item.content = _this.escape2Html(item.content)
      })
      this.topData = res.records.filter(item => { return item.position == 1 })
      this.bottomData = res.records.filter(item => { return item.position == 2 })
      this.$emit('loading', false)
      this.loading = false
    },
    escape2Html (str) {
      var arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t]
      })
    },
    tableHandles (trElements, page, pagesize) {
      // console.log(trElements, page, pagesize)
      for (var k = 1; k < trElements.length - 1; k++) {
        if (k <= page * pagesize)  {
          trElements[k].style.display = 'flex'
        } else {
          trElements[k].style.display = 'none'
        }
      }
      if (trElements.length - 2 <= page * pagesize) {
        trElements[trElements.length - 1].innerHTML = `<td valign="top" colspan="3" rowspan="1" style="word-break: break-all;">加载完毕</td>`
      }
    },
    async getPageData () {
      var res = await getCommonPageData({code: 'internet_disclosure', id: 31})
      // console.log(111111111111111)
      // console.log(res)
      this.richText = res.list[0].content
      let _this = this
      this.$nextTick(() => {
        let elements = document.querySelectorAll('.content a')
        for (var i = 0; i < elements.length; i++) {
          // console.log(elements[i])
          let href = elements[i].getAttribute('href')
          let arr = href.split('.')
          // console.log(arr[arr.length-1])
          let exeName = arr[arr.length-1]
          if (exeName == 'jpg' || exeName == 'png') {
            // console.log(exeName)
            elements[i].onclick = function (e) {
              e.preventDefault();
              _this.previewPic(href)
            }
          }
        }
      })
    },
    previewPic (url) {
      this.previewUrl = url
    },
    async getNav() {
      var res = await getPartnerCompanyTypeList();
      const { list } = res;
      list.forEach(function (item) {
        item.active = false;
      })
      this.nav = [this.nav[0], ...list];
      this.getData();
    },
    async getData() {
      this.loading = true;
      var params = {
        type: this.navId != 0 ? this.navId : '',
        page: this.page,
        limit: 15
      }
      var res = await getPartnerCompanyList(params);
      const { records } = res;
      this.list = [...this.list, ...records];
      this.$emit('loading', false)
      this.maxPage = res.pages;
      this.loading = false;
      if (this.page >= this.maxPage) {
        this.loaded = true;
      }

    },
    // loadMore() {
    //   if (this.page >= this.maxPage) {
    //     return;
    //   }
    //   this.page = this.page + 1;
    //   this.getData();
    // },
    tap(item) {
      this.navId = item.id;
      this.list = [];
      this.page = 1;
      this.loaded = false
      this.getData();

    }
  }
}
</script>
<style lang="scss">
pre {
  font-family: var(--bs-font-sans-serif);
}
.rich-text-container {
  margin-top: .3rem;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .content {
    p {
      margin-bottom: 0!important;
      font-size: 16px;
      line-height: 2em;
    }
  }
}
.table-box {
  margin-top: .3rem;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
}
.content table {
  margin-top: 0.2rem;
  width: 100%;
  border-top-left-radius: 0.16rem;
  border-top-right-radius: 0.16rem;
  overflow: hidden;
  tr {
    border: 1px solid #ebebeb;
    &.firstRow {
      background-color: #fe9022;
      td {
        font-weight: bold;
        color: #ffffff;
        vertical-align: middle;
        border-right: 0 none;
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 0.22rem;
          background-color: #ffffff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        text-align: center;
        // &:nth-of-type(1) {
        //   width: 35%;
        // }
        // &:nth-of-type(2) {
        //   width: 35%;
        // }
        &:last-child {
          // width: 30%;
          &::after {
            content: "";
            width: 1px;
            height: 0.22rem;
            background-color: #ffffff;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
      }
    }
    td {
      height: 0.8rem;
      padding: 0 0.08rem;
      border-right: 1px solid #ebebeb;
      text-align: center;
      font-size: .18rem;
      justify-content: center;
      align-items: center;
      line-height: 1.4;
      vertical-align: middle;
      a {
        color: #0d6efd;
        text-decoration: underline;
      }
    }
    th {
      border-right: 1px solid #ebebeb;
      padding: 0 0.08rem;
      font-weight: 400;
      font-size: .2rem;
    }
  }
}
 .table-box table {
    margin-top: 0.2rem;
    width: 100%;
    border-top-left-radius: 0.16rem;
    border-top-right-radius: 0.16rem;
    overflow: hidden;
    tr {
      border: 1px solid #ebebeb;
      td {
        height: 0.8rem;
        padding: 0 0.08rem;
        border-right: 1px solid #ebebeb;
        text-align: center;
        font-size: .18rem;
        position: relative;
        max-width: 40%;
        .td-content {
          width: 100%;
          // display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
          
        }
        &::after {
          content: "";
          width: 1px;
          height: 0.22rem;
          background-color: #ffffff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &:last-child {
          &::after {
            content: "";
            width: 1px;
            height: 0.22rem;
            background-color: #ffffff;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
        a {
          text-decoration: none;
          color: #333333;
        }
      }
      th {
        border-right: 1px solid #ebebeb;
        padding: 0 0.08rem;
        font-weight: 400;
        font-size: .2rem;        
      }
    }
    .hd {
      background-color: #fe9022;
      height: 0.7rem;
      line-height: 0.7rem;
      text-align: center;
      width: 100%;
      th {
        border: 0;
        text-align: center;
        color: #ffffff;
        position: relative;
        padding: 0 0.04rem;
        line-height: 1.4;
        &::after {
          content: "";
          width: 1px;
          height: 0.22rem;
          background-color: #ffffff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        text-align: center;
        // &:nth-of-type(1) {
        //   width: 35%;
        // }
        // &:nth-of-type(2) {
        //   width: 35%;
        // }
        &:last-child {
          // width: 30%;
          &::after {
            content: "";
            width: 1px;
            height: 0.22rem;
            background-color: #ffffff;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: none;
          }
        }
        // &:nth-of-type(4) {
        //   width: 8%;
        // }
        // &:nth-of-type(5) {
        //   width: 10%;
        // }
        // &:nth-of-type(6) {
        //   width: 10%;
        // }
        // &:nth-of-type(7) {
        //   width: 10%;
        // }
        // &:nth-of-type(8) {
        //   width: 14%;
        //   &::after {
        //     width: 0;
        //   }
        // }
      }
    }
  }
  .fd {
    cursor: pointer;
    width: 100%;
    height: 0.44rem;
    background-color: #fe9022;
    border-bottom-left-radius: 0.16rem;
    border-bottom-right-radius: 0.16rem;
    font-size: 0.14rem;
    color: #ffffff;
    text-align: center;
    line-height: 0.44rem;
    margin-bottom: 0.3rem;
  }
.preview-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  img {
    max-width: 80%;
    max-height: 80%;
  }
  .close-modal {
    width: .82rem;
    height: .82rem;
    background-color: #fe6917;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: .5rem;
    cursor: pointer;
    img {
      width: 45%;
      height: 45%;
      transform: rotate(45deg);
    }
  }
}

@media only screen and (max-width: $moble_width) {
  .content {
    p {
      &.title:first-child {
        margin-top: 0;
      }
    }
  }
  .rich-text-container {
    margin-top: .3rem;
    .title {
      font-size: .2rem;
      font-weight: bold;
    }
    .content {
      p {
        margin-bottom: 0!important;
        font-size: .18rem;
        line-height: 2em;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
table {
  width: 100%;
  tr {
    width: 100%;
  }
}
.page-mechanism {
 
}
@media only screen and (max-width: $moble_width) {
  .page-mechanism {
    .list {
      .li {
        margin: 0;
        width: 30.5%;
        margin-right: 0.14rem;
        margin-bottom: 0.15rem;
        &:nth-of-type(5n) {
          margin-right: 0.14rem;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
